import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import components from "../styles/components.module.css";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className={components.section}>
      <h1>PAGE NOT FOUND</h1>
      <Link to="/" className={components.primary}>
        Go back to the homepage
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
